import React from 'react';

import { Box, Text } from 'rebass';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import { API_ENDPOINT } from '../lib/config';

const postImageStyle = {
  backgroundPosition: 'center',
  backgroundSize: ' cover',
  borderRadius: '4px',
  height: '100%',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
};
const overlayStyle = {
  opacity: '0',
  position: 'relative',
  transition: 'all 0.3s ease',
  color: '#fff',
  width: '100%',
  height: '100%',
  '&:hover': {
    opacity: '1',
  }
};

const titleStyle = {
  bottom: '0',
  width: '100%',
  fontSize: '18px',
  padding: '12px',
  position: 'absolute',
  fontFamily: 'Lato',
  fontWeight: '100',
  background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0,0.6))',
};
const PostItem = ({ post }) => {

  return (
    <Box px={2} sx={{
      width: '20%',
      height: '25vh',
      padding: '10px',
      cursor: 'pointer',
      '@media (max-width: 1024px)': {
        width: '50%',
      },
    }}>
      <Link to={`/post/${post.id}`}>
        <Box sx={{
          backgroundImage: `url('${API_ENDPOINT}${post.Image_URL[0].formats.small.url}')`,
          ...postImageStyle
        }}>
          <Box sx={overlayStyle}>
            <Text sx={titleStyle}>{post.Title}</Text>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

PostItem.propTypes = {
  post: PropTypes.object,
};

PostItem.defaultProps = {
  post: null,
};


export default PostItem;