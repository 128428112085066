import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Flex, Box, Lo } from 'rebass';
import PostItem  from './PostItem'
import { $fetchPosts } from './Shared/state';
import { COLOR } from '../lib/styles';


 const withStore = connect((state) => ({
  posts: state.Shared.posts
}));

const Wrapper = (C) => withStore(C);

const loaderStyle = {
  width:'100%',
  height:'50vh',
  background: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Lato'
}

const PostList = ({ posts, dispatch }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch($fetchPosts())
      .then(() => setLoading(false))
  }, []);
  if(loading) return <Flex sx={loaderStyle} fontSize={[2,3,4]} color={COLOR.primary}> Please wait ...</Flex>
  return (
    <Flex
      sx={{
        gridGap: 5,
        flexWrap: 'wrap',
      }}>
        {posts.map((item, index) => <PostItem key={index} post={item} />)}
    </Flex>
  );
};

export default Wrapper(PostList);