import React from 'react';

import { Heading, Flex, Box, Text, Link } from 'rebass';

import '../lib/init';

import Layout from '../components/Layout';
import PostList from '../components/PostList';

import { COLOR } from '../lib/styles';

const headingStyle = {
  width: '100%',
  zIndex: '10',
  overflow: 'hidden',
  maxWidth: '100%',
  '@media (max-width: 960px)': {
    paddingLeft: '0',
    paddingRight: '0',
  },
};

const linkStyle = {
  minWidth: '250px',
  margin: '24px',
  textDecoration: 'blink',
  color: COLOR.white,
  borderRadius: '25px',
  fontSize: [1, 2, 3],
  fontFamily: 'Lato',
};

export default function Home() {
  return (
    <Layout>
      <Box xs={headingStyle}>
        <Box sx={{ paddingTop: '32px', textAlign: 'center' }}>
          <Text
            fontSize={[4, 5, 6]}
            fontWeight="100"
            fontFamily="Lato"
            textAlign="center"
            marginTop="60px"
            color="#656565"
          >
            New Dotpedia.com under construction
          </Text>
          <Text fontSize={[2, 3, 4]} fontWeight="100" fontFamily="Lato" textAlign="center" color="#656565" py={4}>
            We’re working on the next magnetic sphere platform.
          </Text>
          <Flex p={2} flexWrap="wrap" alignItems="center" justifyContent="center">
            <Link
              variant="nav"
              p={3}
              href="http://nanodots.com/"
              fontSize={[1, 2, 3]}
              color="black"
              bg="#ffea00"
              sx={linkStyle}
            >
              Buy now
            </Link>
            <Link
              variant="nav"
              p={3}
              href="/join-newsletter"
              color="white"
              bg="#227bdf"
              fontSize={[1, 2, 3]}
              sx={linkStyle}
            >
              Join newsletter
            </Link>
          </Flex>
        </Box>
        <Flex>
          <Box p={3} color="white" bg="primary">
            <Heading fontSize={[4, 5, 6]} fontFamily="Lato" fontWeight="100" color="#656565">
              Explore
            </Heading>
          </Box>
        </Flex>
        <PostList />
      </Box>
    </Layout>
  );
}
